import React, { useState, useEffect } from 'react'
import { Liff } from '../utils/liff'
import withQueryString from '../utils/with-query-string'

interface LiffPortalProps {
  queryString: {
    'liff.state'?: string
  }
}

const LiffPortal = ({ queryString }: LiffPortalProps): JSX.Element => {
  const [error, setError] = useState(null)

  useEffect(() => {
    ;(async () => {
      try {
        const liff = new Liff()

        await liff.init()

        const liffState = queryString['liff.state']

        if (!liffState) {
          setError('Please open from Line app')
        } else if (liffState.length === 0) {
          setError('Path is not specified')
        }
      } catch (error) {
        setError(error.message || error)
      }
    })()
  }, [])

  return (
    <div className="p-2">
      {error && <span className="text-red-600">{error}</span>}
    </div>
  )
}

export default withQueryString(LiffPortal)
